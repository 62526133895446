/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { graphql } from 'gatsby';
import Grid from '@material-ui/core/Grid';

import './group.styl';

import MainLayout from '../views/layouts/MainLayout';
import SEO from '../views/components/SEO';
import Parallax from '../views/components/Parallax';
import ResponsiveBgImg from '../views/components/ResponsiveBgImg';

// const unicef = require("../images/icons/unicef-logo.svg");
const unicef = require('../images/site/UNICEF-FR.svg');
const ofg = require('../images/site/OFG.svg');
const eco = require('../images/site/PEFC-FR.svg');
const eco2 = require('../images/site/FSC-FR.svg');

const GroupPage = ({
    pageContext: { locale, originalPath },
    location,
    data,
}) => {
    const aqua = data.aqua.childImageSharp.fluid;
    const merch = data.merch.childImageSharp.fluid;
    const comm = data.comm.childImageSharp.fluid;
    const usine = data.usine.childImageSharp.fluid;

    return (
        <MainLayout
            locale={locale}
            originalPath={originalPath}
            location={location}
        >
            <SEO
                title='pages.group.headerTitle'
                description='pages.group.metaDescription'
                originalPath={originalPath}
                location={location}
                metaIcon={data.seoImage.childImageSharp.resize.src}
            />
            <div className='page-bg'>
                <Parallax
                    img={data.coverImage.childImageSharp.fluid}
                    alt='image-groupe-groupe-editor'
                    overlay='light'
                >
                    <div className='page-intro gallery'>
                        <div className='container'>
                            <FormattedMessage
                                tagName='h1'
                                id='pages.group.title'
                            />
                            <FormattedMessage
                                tagName='p'
                                id='pages.group.description'
                            />
                            <FormattedMessage
                                tagName='p'
                                id='pages.group.description2'
                            />
                        </div>
                    </div>
                </Parallax>
                <div className='page-content'>
                    <section className='knowhow'>
                        {/* <div className="container"> */}
                        <div className='intro'>
                            <FormattedMessage
                                tagName='h2'
                                id='pages.group.knowHow.title'
                            />
                            <FormattedMessage
                                tagName='p'
                                id='pages.group.knowHow.description'
                            />
                        </div>
                        <div className='grid-bloc'>
                            <div className='part'>
                                <div className='card-bg'>
                                    <ResponsiveBgImg
                                        {...aqua}
                                        alt='image-créativité-aquarelle'
                                    />
                                </div>
                                <div className='card-content'>
                                    {/* <h4>
                                        <FormattedMessage id="pages.group.knowHow.part1.head" />
                                    </h4> */}

                                    <FormattedMessage
                                        tagName='h3'
                                        id='pages.group.knowHow.part1.title'
                                    />
                                    <FormattedMessage
                                        tagName='p'
                                        id='pages.group.knowHow.part1.bloc1'
                                    />
                                    <FormattedMessage
                                        tagName='p'
                                        id='pages.group.knowHow.part1.bloc2'
                                    />
                                    <FormattedMessage
                                        tagName='p'
                                        id='pages.group.knowHow.part1.bloc3'
                                    />
                                </div>
                            </div>
                            <div className='part'>
                                <div className='card-bg'>
                                    <ResponsiveBgImg
                                        {...usine}
                                        alt='image-outil-production'
                                    />
                                </div>
                                <div className='card-content'>
                                    {/* <h4>
                                        <FormattedMessage id="pages.group.knowHow.part2.head" />
                                    </h4> */}
                                    <FormattedMessage
                                        tagName='h3'
                                        id='pages.group.knowHow.part2.title'
                                    />
                                    <FormattedMessage
                                        tagName='p'
                                        id='pages.group.knowHow.part2.bloc1'
                                    />
                                    <FormattedMessage
                                        tagName='p'
                                        id='pages.group.knowHow.part2.bloc2'
                                    />
                                    <FormattedMessage
                                        tagName='p'
                                        id='pages.group.knowHow.part2.bloc3'
                                    />
                                </div>
                            </div>
                            <div className='part'>
                                <div className='card-bg'>
                                    <ResponsiveBgImg
                                        {...comm}
                                        alt='image-organisation-commerciale'
                                    />
                                </div>
                                <div className='card-content'>
                                    {/* <h4>
                                        <FormattedMessage id="pages.group.knowHow.part3.head" />
                                    </h4> */}
                                    <FormattedMessage
                                        tagName='h3'
                                        id='pages.group.knowHow.part3.title'
                                    />
                                    <FormattedMessage
                                        tagName='p'
                                        id='pages.group.knowHow.part3.bloc1'
                                    />
                                    <FormattedMessage
                                        tagName='p'
                                        id='pages.group.knowHow.part3.bloc2'
                                    />
                                    <FormattedMessage
                                        tagName='p'
                                        id='pages.group.knowHow.part3.bloc3'
                                    />
                                </div>
                            </div>
                            <div className='part'>
                                <div className='card-bg'>
                                    <ResponsiveBgImg
                                        {...merch}
                                        alt='image-solution-merchandising'
                                    />
                                </div>
                                <div className='card-content'>
                                    {/* <h4>
                                        <FormattedMessage id="pages.group.knowHow.part4.head" />
                                    </h4> */}
                                    <FormattedMessage
                                        tagName='h3'
                                        id='pages.group.knowHow.part4.title'
                                    />
                                    <FormattedMessage
                                        tagName='p'
                                        id='pages.group.knowHow.part4.bloc1'
                                    />
                                    <FormattedMessage
                                        tagName='p'
                                        id='pages.group.knowHow.part4.bloc2'
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className='values'>
                        <div className='container'>
                            <div className='intro'>
                                <FormattedMessage
                                    tagName='h2'
                                    id='pages.group.value.title'
                                />
                                {/* <p>
                                    <FormattedMessage id="pages.group.value.description" />
                                </p> */}
                            </div>
                            <Grid
                                container
                                spacing={3}
                                className='parts cards-bloc'
                            >
                                <Grid
                                    item
                                    xs={12}
                                    md={4}
                                    style={{ alignSelf: 'stretch' }}
                                >
                                    <div className='part card'>
                                        <div
                                            className='icon-bloc'
                                            style={{ width: 'auto' }}
                                        >
                                            <div
                                                className='icon'
                                                style={{
                                                    backgroundImage: `url(${ofg})`,
                                                    // backgroundColor: "#0163a2"
                                                }}
                                            />
                                        </div>

                                        <FormattedMessage
                                            id='pages.group.value.part1.title'
                                            tagName='h3'
                                        />

                                        <FormattedMessage
                                            tagName='p'
                                            id='pages.group.value.part1.description'
                                        />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    md={4}
                                    style={{ alignSelf: 'stretch' }}
                                >
                                    <div className='part card'>
                                        <div className='icon-bloc'>
                                            <div
                                                className='icon'
                                                style={{
                                                    backgroundImage: `url(${eco})`,
                                                    width: '45%',
                                                    // backgroundColor: "#0163a2"
                                                }}
                                            />
                                            <div
                                                className='icon'
                                                style={{
                                                    backgroundImage: `url(${eco2})`,
                                                    width: '45%',
                                                }}
                                            >
                                                <FormattedMessage
                                                    tagName='p'
                                                    id='pages.group.value.part2.note'
                                                />
                                            </div>
                                        </div>
                                        <FormattedMessage
                                            id='pages.group.value.part2.title'
                                            tagName='h3'
                                        />
                                        <FormattedHTMLMessage
                                            tagName='p'
                                            id='pages.group.value.part2.description'
                                        />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    md={4}
                                    style={{ alignSelf: 'stretch' }}
                                >
                                    <div className='part card'>
                                        <div className='icon-bloc'>
                                            <div
                                                className='icon'
                                                style={{
                                                    backgroundImage: `url(${unicef})`,
                                                    // backgroundColor: "#00AEEF"
                                                }}
                                            />
                                        </div>
                                        <FormattedMessage
                                            id='pages.group.value.part3.title'
                                            tagName='h3'
                                        />
                                        <FormattedMessage
                                            tagName='p'
                                            id='pages.group.value.part3.description'
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </section>

                    <section className='story'>
                        <div className='container'>
                            <div className='intro'>
                                <FormattedMessage
                                    tagName='h2'
                                    id='pages.group.history.title'
                                />
                                <p>
                                    <FormattedMessage id='pages.group.history.description1' />
                                    <br />
                                    <FormattedMessage id='pages.group.history.description2' />
                                </p>
                            </div>
                            <div className='grid-bloc'>
                                <FormattedMessage
                                    tagName='p'
                                    id='pages.group.history.text1'
                                />
                                <FormattedMessage
                                    tagName='p'
                                    id='pages.group.history.text2'
                                />
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </MainLayout>
    );
};

GroupPage.propTypes = {
    pageContext: PropTypes.shape({
        locale: PropTypes.string.isRequired,
        originalPath: PropTypes.string.isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
};

export default GroupPage;

export const query = graphql`
    query {
        coverImage: file(
            sourceInstanceName: { eq: "parallax" }
            relativePath: { eq: "groupe-v5.jpg" }
        ) {
            ...BgImages
        }
        seoImage: file(
            sourceInstanceName: { eq: "parallax" }
            relativePath: { eq: "groupe-v5.jpg" }
        ) {
            ...SeoImages
        }
        aqua: file(
            sourceInstanceName: { eq: "images" }
            name: { eq: "creation" }
        ) {
            ...GroupImages
        }
        merch: file(
            sourceInstanceName: { eq: "images" }
            name: { eq: "merch" }
        ) {
            ...GroupImages
        }
        comm: file(
            sourceInstanceName: { eq: "images" }
            name: { eq: "organisation-commercial" }
        ) {
            ...GroupImages
        }
        usine: file(
            sourceInstanceName: { eq: "images" }
            name: { eq: "production2" }
        ) {
            ...GroupImages
        }
    }
`;
